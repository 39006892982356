import React from 'react'
import './getStarted.css'

const GetStarted = () => {
  return (
    <div className='app__getStarted'>
      <button type='button'>Get Started</button>
    </div>
  )
}

export default GetStarted
